<script>
export default {
  props: { currentEmpresa: { type: Object } },
  components: {
    // Multiselect,
  },
  data() {
    return {
      convenio: {
        id: null,
        name: null,
        empresa_id: null
      },
      loader: {
        get: false,
      },
    };
  },
  mounted() {
    this.$emit("newTitle", "Incluir Convênio");
    this.convenio.empresa_id = this.currentEmpresa.id;
  },
  methods: {
    setPost() {
      this.$emit("doPost", this.convenio);
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        
        <div class="col-md-12">
          <label for="name">* Nome do Convênio</label>
          <input id="name" v-model="convenio.name" type="text" class="form-control" />
        </div>
      </div>
      
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button v-if="!loader.get" class="btn btn-success" @click.prevent="setPost()">
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>