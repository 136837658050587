<script>
export default {
  props: {
    convenio: { type: Object },
  },
  data() {
    return {
      
    }
  },
  computed: {
    indEstoque() {
      switch (this.convenio.ind_est) {
        case 1:
          return 'Estoque de propriedade do informante e em posse de terceiros';
        case 2:
          return 'Estoque de propriedade de terceiros e em posse do informante';
        default:
          return 'Estoque de propriedade do informante e em seu poder';
      }
    },
  },
}
</script>

<template>
<div class="convenio">
  <p>ID: {{ convenio.id }}</p>
  <p>Nome: {{ convenio.name }}</p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ convenio.created_at }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ convenio.updated_at }}</b-badge></p>
</div>
</template>