<script>
import Detail from "./detail";
export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listConvenios: { type: Array, required: true },
    hide: { type: Boolean, required: true }
  },
  components: { Detail },
  data() {
    return {
      titleBody: 'Listagem de Convenios',
      currentConvenio: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true, tdClass: 'text-center', thClass: 'text-center' },
        { label: 'Nome', key: 'name', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
        // { label: "Ind. Est.", key: "ind_est", sortable: true, thClass: 'text-center' },
        // { label: "Ativo", key: "status", sortable: true, thClass: 'text-center' },
        { label: "Ações", key: "acoes", sortable: false, tdClass: 'text-center', thClass: 'text-center' }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.listConvenios.length) ? this.listConvenios.length : 0;
    },
    clonefoot() {
      return (this.listConvenios.length || this.listConvenios.length > 10) ? true : false;
    },
    indEstoque() {
      switch (this.localEstoque.ind_est) {
        case 1:
          return 'Estoque de propriedade do informante e em posse de terceiros';
        case 2:
          return 'Estoque de propriedade de terceiros e em posse do informante';
        default:
          return 'Estoque de propriedade do informante e em seu poder';
      }
    },
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editar(local) {
      this.$emit('edit', local);
    },
    excluir(local) {
      this.$emit('doDelete', local);
    },
    setLocalEstoque(local) {
        this.currentConvenio = local;
    },
  },
}
</script>

<template>
<div class="card-body" v-if="hide">
  <div class="row mt-4">
    <!-- Start Limit -->
    <div class="col-sm-12 col-md-6">
      <div id="local-estoque-table_length" class="dataTables_length">
        <label class="d-inline-flex align-items-center">
          Exibir&nbsp;
          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
        </label>
      </div>
    </div>
    <!-- End Limit -->
    <!-- Start Search -->
    <div class="col-sm-12 col-md-6">
      <div id="local-estoque-table_filter" class="dataTables_filter text-md-right">
        <label class="d-inline-flex align-items-center">
          Pesquisar:
          <b-form-input
          v-model="filter"
          type="search"
          placeholder="Pesquisar..."
          class="form-control form-control-sm ml-2"
          ></b-form-input>
        </label>
      </div>
    </div>
    <!-- End search -->
  </div>
   <!-- Start Table -->
   <div class="row mt-4">
    <div v-if="hide" class="table-responsive mb-0">
      <b-table
       :items="listConvenios"
       :fields="fields"
       responsive="sm"
       :per-page="perPage"
       :current-page="currentPage"
       :sort-by.sync="sortBy"
       :sort-desc.sync="sortDesc"
       :filter="filter"
       :filter-included-fields="filterOn"
       @filtered="onFiltered"
       :hover=true
       :foot-clone=clonefoot
      >
        <template #cel(ind_est)="row">
          {{ (row.item.ind_est === 0) ? 'Estoque de propriedade do informante e em posse de terceiros' : 
            'Estoque de propriedade de terceiros e em posse do informante' }}
        </template>

        <template #cell(status)="row">
          {{ (row.item.status ===1) ? 'Sim' : 'Não' }}
        </template>
        <template #cell(acoes)="row">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown v-bind:id="'dropdown-'+ row.item.id" class="m-md-2">
            <template #button-content>
              <i data-v-6289eca4="" class="bx ri-menu-line"></i>
            </template>
            <b-dropdown-item v-b-modal.modal-local-estoque @click="setLocalEstoque(row.item)"><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item>
            <b-dropdown-item @click="editar(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
            <b-dropdown-item @click="excluir(row.item)"><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
   </div>
   <!-- End Table -->
   <!-- Start Pagination -->
  <div v-if="hide" class="row mt-4">
    <div class="col">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Pagination -->
  <!-- Start Modal Centro Custo -->
  <b-modal
   v-if="hide"
   hide-footer
   id="modal-local-estoque"
   :title="'Local de Estoque'"
   title-class="font-18"
  >
    <Detail :convenio="currentConvenio" />
  </b-modal>
  <!-- End Modal Centro Custo -->
</div>
</template>